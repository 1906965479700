<template>
    <div class="settings-list">
        <b-collapse
            v-for="section of settings"
            :key="section.title"
            class="accordion"
            animation="slide"
            :open="section.open"
            @close="closeSection(section.slug)"
            @open="setOpenBySlug(section.slug)">
            <div slot="trigger"
                 slot-scope="props"
                 :class="{ 'accordion-header': true, open: section.open }"
                 role="button">
                <a class="accordion-header-icon mr-2 mt-2">
                    <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
                </a>
                <div class="accordion-header-title" style="width: 100%">
                    <div class="columns">
                        <h2 class="column is-narrow">{{ $t(section.title) }}</h2>
                        <div v-if="!section.data.length"
                             class="has-text-danger column is-marginless has-no-entries">
                            {{ $t('You have no entries in this list yet') }}
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'accordion-content': true, open: section.open }">
                <SettingsTable :section="section" />
            </div>
        </b-collapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import settings from './sectioncomponents/index'
    import SettingsTable from './components/SettingsTable'
    import i18n from '@/config/i18n'

    export default {
        name: 'SettingsList',
        components: { SettingsTable },
        extraTranslatableStrings: [
            i18n.t('name'),
            i18n.t('product'),
            i18n.t('area_of_application'),
            i18n.t('dosage'),
            i18n.t('area'),
            i18n.t('color'),
            i18n.t('food_group'),
            i18n.t('used_food'),
            i18n.t('lastname_and_firstname'),
            i18n.t('function'),
            i18n.t('email'),
            i18n.t('phone'),
            i18n.t('cleaning_agent'),
            i18n.t('cleaning_interval'),
            i18n.t('frying_oil'),
            i18n.t('food_groups'),
            i18n.t('supplier'),
            i18n.t('order_type'),
            i18n.t('contact_person'),
            i18n.t('customer_number'),
            i18n.t('delivery_date'),
            i18n.t('description'),
            i18n.t('description_serialno_device')
        ],
        data() {
            return { openSections: [] }
        },
        computed: {
            settings() {
                return settings
                    .map(section => ({
                        ...section,
                        open: this.isOpen(section),
                        data: this.getPropertyForSection(section.component.name, 'data'),
                        name: this.lowerFirst(section.component.name),
                        introText: this.getPropertyForSection(section.component.name, 'introText'),
                        categories: this.getPropertyForSection(section.component.name, 'categories')
                    }))
                    // show only the sections allowed by company category
                    .filter(section => this.company_category && section.categories ? section.categories.includes(this.company_category) : true)
            },
            ...mapState('settings', [ 'open' ]),
            ...mapState('auth', [ 'company_category' ]),
            slug() {
                return this.$route.hash.replace('#', '')
            }
        },
        watch: {
            slug(slug) {
                this.setOpenBySlug(slug)
            }
        },
        mounted() {
            this.setOpenBySlug(this.slug)
        },
        methods: {
            getPropertyForSection(section, propertyName) {
                const sectionName = this.lowerFirst(section)
                let value
                const state = this.$store.state.settings

                if (Object.prototype.hasOwnProperty.call(state, sectionName)) {
                    const stateSection = state[sectionName]
                    if (Object.prototype.hasOwnProperty.call(stateSection, propertyName))
                        value = stateSection[propertyName]
                }

                return value
            },
            lowerFirst(string) {
                return string.charAt(0).toLowerCase() + string.slice(1)
            },
            setOpenBySlug(slug) {
                this.openSections.push(slug)
                if (slug !== this.slug)
                    this.$router.push({
                        ...this.$route,
                        hash: '#' + slug
                    })
            },
            closeSection(slug) {
                this.openSections = this.openSections.filter(name => name !== slug)
                if (slug === this.slug)
                    this.$router.push({
                        ...this.$route,
                        hash: ''
                    })
            },
            isOpen(section) {
                return this.openSections.includes(section.slug)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "~@/assets/vars";

    .accordion {
        padding-bottom: 0;
    }

    .accordion-header {
        border-color: $light-grey;
        height: 70px;

        &.open h2 {
            font-weight: normal;
        }
    }

    .accordion-content {
        padding-top: 1px;
    }

    .settings-list {
        background-color: $white;
        padding: 32px 40px;

        h2 {
            display: inline
        }
    }

    .has-no-entries {
        margin-left: 1em;
        font-size: .6rem;
        padding-top: 0;
        @include tablet {
            padding-top: 0.75rem;
            align-self: flex-end;
            padding-bottom: 1.3rem;
        }
    }
</style>

