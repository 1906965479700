<template>
    <div class="settings-table">
        <b-message type="is-info message-like message--small mb-0">
            <BulbIcon />
            {{ section.introText }}
        </b-message>

        <b-table :data="data"
                 hoverable
                 @click="row => $refs[`${section.name}_${row.id}`].$el.click()">
            <template slot-scope="props">
                <b-table-column v-for="(value, field) in visibleFields(props.row)"
                                :key="`${props.row.id}-${field}`" :field="field" :label="$t(field)"
                                :width="section.columnWidths?section.columnWidths[field]:undefined">
                    <b-icon v-if="value === true" icon="check" />
                    <b-icon v-else-if="value === false" icon="close" />
                    <span v-else-if="section.name === 'inventory' && field === 'cleaning_interval'">
                        {{ inventoryCleaningInterval(value) }}
                    </span>
                    <span v-else>{{ value }}</span>
                </b-table-column>
                <b-table-column :label="$t('Edit')" width="30" class="action">
                    <router-link :ref="`${section.name}_${props.row.id}`"
                                 :to="{ name: 'settings.update', params: {
                                     section: section.name,
                                     id: props.row.id
                                 } }">
                        <EditIcon />
                    </router-link>
                </b-table-column>
                <b-table-column :label="$t('Delete')" width="30" class="action">
                    <a @click.stop="removeItem( { section: section.name, id: props.row.id })">
                        <DeleteIcon />
                    </a>
                </b-table-column>
            </template>
        </b-table>
        <fieldset class="fieldset buttonset">
            <router-link :to="{ name: 'settings.create', params: { section: section.name } }"
                         tag="b-button" class="is-info right">
                {{ $t('New entry') }}
            </router-link>
        </fieldset>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import DeleteIcon from '@/components/icons/Delete'
    import EditIcon from '@/components/icons/Edit'
    import BulbIcon from '@/components/icons/Bulb'
    import { availableIntervals } from '../const'

    export default {
        name: 'SettingsTable',
        components: {
            DeleteIcon,
            EditIcon,
            BulbIcon
        },
        props: {
            section: {
                type: Object,
                required: true
            }
        },
        data() {
            return { availableIntervals: availableIntervals }
        },
        computed: {
            data() {
                return this.section.data
            }
        },
        methods: {
            visibleFields(row) {
                const visible = { ...row }

                // Remove all columns which don't add value for the end user
                delete visible.id
                delete visible.uuid
                delete visible.entries

                return visible
            },
            ...mapActions('settings', [ 'removeSectionItem' ]),
            removeItem(payload) {
                this.$buefy.dialog.confirm({
                    message: this.$t('Do you really want to delete this item?'),
                    onConfirm: () => {
                        this.removeSectionItem(payload)
                        this.$buefy.toast.open('Item has been deleted.')
                    }
                })
            },
            inventoryCleaningInterval(value) {
                return this.availableIntervals.find(ai => ai.key === value)?.label
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '@/assets/vars.scss';

    .settings-table {

        ::v-deep {
            .b-table {
                padding-left: 40px;

                .table {
                    tr {
                        cursor: pointer;

                        td,
                        th {
                            border-bottom-width: 1px;
                            border-color: $light-grey;
                            padding: 0 8px;
                            height: 42px;
                            vertical-align: middle;
                        }

                        th {
                            line-height: 1.3rem;
                            white-space: initial;

                            &:nth-last-child(2),
                            &:nth-last-child(1) {
                                div {
                                    display: none;
                                }
                            }
                        }

                        td {
                            border-color: $light-grey;
                            border-right: #d0d0d0 0 solid;
                            font-size: 0.8125rem;

                            &:last-child {
                                border-right: none;
                            }

                            a {
                                display: block;
                                line-height: 0;

                                &:hover {
                                    svg {
                                        path,
                                        g {
                                            fill: $red;
                                        }
                                    }
                                }
                            }

                            &.action {
                                text-align: center;
                                vertical-align: middle;
                            }
                        }

                        &:hover {
                            svg {
                                g {
                                    fill: $greyish-brown;
                                }
                            }
                        }
                    }
                }
            }
        }

        .buttonset {
            padding-top: 1.5rem;
            text-align: right;
        }
    }
</style>
